function PanasonicAdditionalInfo({ modelInfo }) {
    if (modelInfo.underpowered) {
        return (
            <div className="warning">
                Device with <em>mt5581</em>, which is underpowered for DAZN 2.0 on SDKv3.
            </div>
        );
    }

    return null
}

export default PanasonicAdditionalInfo;
