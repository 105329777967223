import "./App.css";
import React from 'react';
import { manufacturers } from './constants';
import { isSamsung, getSamsungDetails } from './manufacturers/samsung';
import { skyRegex, getSkyDetails } from './manufacturers/sky';
import { isLG, getLgDetails } from './manufacturers/lg';
import GithubCorner from 'react-github-corner';
import logo from './tvid.svg';
import LgAdditionalInfo from './manufacturers/LgAdditionalInfo';
import { getPanasonicDetails, isPanasonic } from "./manufacturers/panasonic";
import PanasonicAdditionalInfo from './manufacturers/PanasonicAdditionalInfo';


function detectManufacturer(modelNumber) {
    if (isSamsung(modelNumber)) {
        return manufacturers.SAMSUNG;
    }

    if (isLG(modelNumber)) {
        return manufacturers.LG;
    }

    if (modelNumber.match(skyRegex)) {
        return manufacturers.SKY;
    }

    if (isPanasonic(modelNumber)) {
        return manufacturers.PANASONIC;
    }
};

class App extends React.Component {
    constructor() {
        super();
        this.state = { modelInfo: null }
    }

    componentDidMount() {
        const params = (new URL(document.location)).searchParams;
        const modelNumberFromUrl = params.get('modelNumber');

        if (modelNumberFromUrl) {
            document.getElementsByClassName('model-info-input')[0].value = modelNumberFromUrl;
            this.updateModelInfo({ target: { value: modelNumberFromUrl } })
        }
    }

    updateModelInfo(event) {
        const modelNumber = event.target.value;
        const manufacturer = detectManufacturer(modelNumber);
        window.history.replaceState({}, '', modelNumber ? `?modelNumber=${modelNumber}` : '/');

        if (manufacturer === manufacturers.SAMSUNG) {
            const modelInfo = getSamsungDetails(modelNumber);
            this.setState({ modelInfo })
            return
        }

        if (manufacturer === manufacturers.LG) {
            const modelInfo = getLgDetails(modelNumber);
            this.setState({ modelInfo })
            return
        }

        if (manufacturer === manufacturers.SKY) {
            const modelInfo = getSkyDetails(modelNumber);
            this.setState({ modelInfo })
            return
        }

        if (manufacturer === manufacturers.PANASONIC) {
            const modelInfo = getPanasonicDetails(modelNumber);
            this.setState({ modelInfo })
            return
        }

        this.setState({ modelInfo: null })
    }

    render() {
        return (
            <div className="App">
                <img src={logo} alt="Logo" />
                <h1> TV Identifier </h1>
                {this.state.modelInfo && this.state.modelInfo.manufacturer === 'lg' &&
                    <h2 className="warning"> WARNING! LG Identification doesn't work as expected, please double-check below. </h2>
                }
                <h3>For more information on device visit <a href="https://www.displayspecifications.com/">displayspecifications.com</a></h3>
                <input
                    onChange={this.updateModelInfo.bind(this)}
                    className="model-info-input"
                    placeholder="Insert a model number like 32LM630BPLA"
                    autoFocus
                />
                {
                    this.state.modelInfo && (
                        <div>
                            <h3>Model Info </h3>
                            <table className="model-info-table">
                                <tbody>
                                    <tr>
                                        <td>Manufacturer</td>
                                        <td>{this.state.modelInfo.manufacturer}</td>
                                    </tr>
                                    <tr>
                                        <td>OS</td>
                                        <td>{this.state.modelInfo.os}</td>
                                    </tr>
                                    <tr>
                                        <td>OS Version</td>
                                        <td>{this.state.modelInfo.osVersion}</td>
                                    </tr>
                                    <tr>
                                        <td>Year</td>
                                        <td>{this.state.modelInfo.year}</td>
                                    </tr>
                                    <tr>
                                        <td>Region</td>
                                        <td>{this.state.modelInfo.region}</td>
                                    </tr>
                                    <tr>
                                        <td>Size</td>
                                        <td>{this.state.modelInfo.size}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3>Manufacturer specific</h3>
                            <table className="model-info-table">
                                {this.state.modelInfo.manufacturer === 'samsung' &&
                                    <tbody>
                                        <tr>
                                            <td>Model Group (<a href="https://developer.samsung.com/smarttv/develop/specifications/tv-model-groups.html">Check</a>)</td>
                                            <td>
                                                {this.state.modelInfo.modelGroup.prefix}
                                                {this.state.modelInfo.modelGroup.groupName} ({this.state.modelInfo.modelGroup.matchingPart})
                                            </td>
                                        </tr>
                                    </tbody>
                                }

                                {this.state.modelInfo.manufacturer === 'sky' &&
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{this.state.modelInfo.modelName}</td>
                                        </tr>
                                        <tr>
                                            <td>Code name</td>
                                            <td>{this.state.modelInfo.modelCodeName}</td>
                                        </tr>
                                        <tr>
                                            <td>Variant</td>
                                            <td>{this.state.modelInfo.variant}</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                            {this.state.modelInfo.manufacturer === 'lg' && <LgAdditionalInfo modelInfo={this.state.modelInfo} />}
                            {this.state.modelInfo.manufacturer === 'panasonic' && <PanasonicAdditionalInfo modelInfo={this.state.modelInfo} />}
                        </div>
                    )
                }
                <h3> Examples </h3>
                <ul className="examples">
                    <li>Samsung: UE55F8000STXXU</li>
                    <li>LG: 32LM630BPLA</li>
                    <li>Sky: ESd160s</li>
                </ul>
                <GithubCorner
                    href="https://github.com/getndazn/tv-identifier"
                    bannerColor="#F7FF1A"
                    octoColor="#0C161C"
                />
            </div>
        );
    }
}

export default App;
