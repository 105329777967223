const yearCodeMap = {
    Q: 2022,
    P: 2021,
    N: 2020,
    M: 2019,
    K: 2018,
    J: 2017,
    H: 2016,
    F: 2015,
    G: 2015,
    B: 2014,
    C: 2014,
    "3": 2023,
    "2": 2022,
    "1": 2021,
    X: 2020,
    "9": 2019,
    "8": 2018,
    "7": 2017,
    "6": 2016
}

const parsers = [
    {
        name: "LG LED TVs",
        examples: ["32LQ630B6LA", "79UF9500", "50UN6950ZUA", "86UK6570AUA"],
        regexp: new RegExp("^"
            + "([0-9]{2})" // size of the Panel
            + "(S|U|E|L|P)" // type of panel
            + `(${Object.keys(yearCodeMap).join("|")})` // Year
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                size,
                , // type,
                yearCode
            ] = modelNumber.match(regexp);

            return {
                size,
                year: yearCodeMap[yearCode]
            };
        }
    },
    {
        name: "LG NanoCell and QNED, 2020-2022",
        examples: ["43NANO75UQA", "55NANO926PB", "75NANO97UNA", "65QNED99UPA"],
        regexp: new RegExp("^"
            + "([0-9]{2})" // size of the Panel
            + "(NANO|QNED)" // NanoCell or QNED
            + "(.{3})" // series, model, tuner type (unimportant here)
            + `(${Object.keys(yearCodeMap).join("|")})` // Year
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                size,
                , // panel
                , // series, model, tuner type 
                yearCode
            ] = modelNumber.match(regexp);

            return {
                size,
                year: yearCodeMap[yearCode]
            };
        }
    },
    {
        name: "LG OLED TV, mean 2016-2022",
        examples: ["OLED65C2SW", "OLED65C6P", "OLED65W8PUA"],
        regexp: new RegExp(""
            + "^OLED"
            + "([0-9]{2})" // size of the Panel
            + "(B|C|G|E|W|R|Z)" // Model
            + `(${Object.keys(yearCodeMap).join("|")})` // Year
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                size,
                , // model
                yearCode
            ] = modelNumber.match(regexp);

            return {
                size,
                year: yearCodeMap[yearCode]
            };
        }
    },
]

const isLG = (modelNumber) => {
    return parsers.some(
        parser => parser.regexp.test(modelNumber)
    )
}

const nonSmartTv = {
    '32LF510B': 'Not a webOS. We bought this one for Helen by mistake, confirmed by LG',
}

function getLgDetails(modelNumberParam) {
    const modelNumber = modelNumberParam.toUpperCase()

    if (nonSmartTv[modelNumber]) {
        return {
            manufacturer: 'lg',
            nonSmartTv: nonSmartTv[modelNumber],
        };
    }

    const parser = parsers.find(({ regexp }) => regexp.test(modelNumber));
    const { size = "", year = "" } = parser.getData(parser.regexp, modelNumber)

    let os, osVersion;
    switch (year) {
        case 2022:
            os = 'webOS';
            osVersion = '22'
            break
        case 2021:
            os = 'webOS';
            osVersion = '6.x'
            break
        case 2020:
            os = 'webOS';
            osVersion = '5.x'
            break
        case 2019:
        case 2018:
            os = 'webOS';
            osVersion = '4.x'
            break
        case 2017:
        case 2016:
            os = 'webOS';
            osVersion = '3.x'
            break
        case 2015:
            os = 'webOS';
            osVersion = '2.x'
            break
        case 2014:
            os = 'webOS';
            osVersion = '1.x'
            break
        default:
    }

    return {
        manufacturer: 'lg',
        year,
        os,
        osVersion,
        size
    }
}

export {
    isLG, getLgDetails, parsers
}
