//Source https://developer.samsung.com/smarttv/develop/specifications/tv-model-groups.html
const modelGroupsMap = {
    2022: {
        "22TV_PREMIUM1": [
            "QBQ900", "QBQ850", "QBQ800", "MBWS1", "G97NB"
        ],
        "22TV_PREMIUM2": [
            "QBQ700", "G85NB", "G75NB", "G95NB"
        ],
        "22TV_PREMIUM3 ": [
            "LS04B", "LS03B", "QBQ75", "QBQS95", "QBQD90", "QBQ9X", "QBQ9D", "QBQ95", "QBQ90S", "QBQ90", "QBQ8D", "QBQ85D", "QBQ85", "QBQ83", "QBQ80", "QBQ7X", "QBQ70", "QBQX3", "LS01B", "LS05B", "G65B", "G70B", "G8QNB", "M80B"
        ],
        "22TV_STANDARD1": [
            "QBQ6D", "QBQ65", "QBQ63", "QBQ60_DPP", "QBQ60", "M70BO", "M70B", "M50B"
        ],
        "22TV_BASIC1": [
            "LS03BS", "UBU7000", "UBU8000", "LSP3B"
        ],
    },
    2021: {
        "21TV_PREMIUM1": [
            "QAQ9X", "QAQ9D", "QAQ95", "QAQ90_NS", "QAQ90", "QAQ8D", "QAQ85_NS", "QAQ85D", "QAQ85", "QAQ80_NS", "QAQ80", "QAQ7D", "QAQ70_NS", "QAQ70", "LST5A", "QAQ7A", "LS03A_NS", "LS03AP", "LS03A", "LS03APD", "LS03AD"
        ],
        "21TV_PREMIUM2": [
            "QAQ900", "QAQ850", "QAQ800", "QAQ700_P", "MAWS1"
        ],
        "21TV_PREMIUM3": [
            "QAQ700", "QAQ700_S"
        ],
        "21TV_STANDARD1": [
            "UAU9070", "UAU9000", "QAQ6D", "QAQ60", "UAU850D", "UAU8000", "LS05AL"
        ],
        "21TV_BASIC1": [
            "QAQ6D_K", "QAQ60_K", "QAQ50_K", "UAUE60A", "UAU7500", "UAU7000", "UAUE70A", "UAU850D_K", "UAU8000_K", "UAU7700", "LSP3", "BEA_HB"
        ],
    },
    2020: {
        "20TV_PREMIUM": ["QTQ800", "QTQ800_NS", "QTQ850", "QTQ900", "QTQ950", "QTQ950_NS", "LSR9", "LST7T", "QTQ70", "QTQ70_NS", "QTQ72", "QTQ7D", "QTQ7X", "QTQ80", "QTQ80_NS", "QTQ8D", "QTQ8E", "QTQ90", "QTQ90_NS", "QTQ95", "QTQ95_NS", "LS05T", "LS03T", "LS03T_NS", "LS01T", "LS01T_NS", "MTWS1"],
        "20TV_STANDARD": ["QTQ60", "QTQ62", "QTQ6D", "QTQ6E", "QTQ6X", "UTU8500", "UTU850D", "UTU8510", "UTU8570", "UTU85A0", "QTQ6AC", "QTQ6SC", "QTQ6XC", "UTU8000", "UTU800D", "UTU8200", "UTU8300", "UTU8400"],
        "20TV_BASIC1": ["LS03TS", "UTU7000", "UTU700D", "UTU7090"],
        "20TV_BASIC2": ["UT4310", "UT4500", "UT4700", "UT5310", "UT5500", "UT5770", "UT4300", "UT4400", "UT5300", "UT430E", "UT530E"]
    },
    2019: {
        "19TV_PREMIUM": ["QRQ900B", "QRQ90", "QRQ85_OC", "QRQ80_OC", "QRQ80", "QRQ75S", "QRQ75", "QRQ70", "QRQ6X", "QRQ69", "QRQ65A", "QRQ60", "LS03R", "LS05R", "LS01R", "URU800D", "URU8000", "MLS07R"],
        "20TV_STANDARD": ["QTQ60", "QTQ62", "QTQ6D", "QTQ6E", "QTQ6X", "UTU8500", "UTU850D", "UTU8510", "UTU8570", "UTU85A0", "QTQ6AC", "QTQ6SC", "QTQ6XC", "UTU8000", "UTU800D", "UTU8200", "UTU8300", "UTU8400"],
        "20TV_BASIC1": ["LS03TS", "UTU7000", "UTU700D", "UTU7090"],
        "20TV_BASIC2": ["UT4310", "UT4500", "UT4700", "UT5310", "UT5500", "UT5770", "UT4300", "UT4400", "UT5300", "UT430E", "UT530E"]
    },
    2018: {
        "18TV_PREMIUM": ["QRQ900", "QNQ9S", "QNQ9F", "QNQ8FB", "QNQ8F", "QNQ8C", "QNQ7FH", "QNQ7F", "QNQ7C", "QNQ75F", "QNQ75C", "QNQ6FK", "QNQ6F", "QNQ65FB", "QNQ65F", "UNU850D", "UNU8500", "UNU80A0", "UNU800D", "UNU8000", "UNU76A0", "UNU7500", "UNU74A0", "UNU7450", "UNU7400", "ULS03NU"],
        "18TV_STANDARD1": ["UNU730D", "UNU7300", "UNU710D", "UNU7103", "UNU7100"],
        "18TV_STANDARD2": ["URU7000", "UNU7120", "UNU709D", "UNU7090", "UNU7080", "UNU7050", "UNU6950"],
        "18TV_STANDARD3": ["UN5510", "UN5500", "UN5350", "UN5305", "UN5300", "UN5200", "UN4510", "UN4500", "UN4350", "UN4310", "UN4300", "BERTB", "BERTA"],
    },
    2017: {
        "17TV_PREMIUM": ["QMS9Y", "QMQ9FR", "QMQ9F", "QMQ8CR", "QMQ8C", "QMQ7FVRE", "QMQ7FRE", "QMQ7FR", "QMQ7FDR", "QMQ7FD", "QMQ7F", "QMQ7CRE", "QMQ7CR", "QMQ7CDR", "QMQ7CD", "QMQ7C", "QMQ6FRE", "UMQS9", "UMU900R", "UMU9000", "UMU800R", "UMU8000", "UMU750R", "UMU7500", "UMU70AR", "UMU70A0", "UMU700R", "UMU7000", "UMU66A0", "UMU6500", "UMU64A0", "UMU6470", "UMU6400", "UMLS003", "ULS003U", "UMU66AR", "UMU650R", "UMU64AR", "UMU640R", "UMU6303", "UMU6302", "UMU6300", "UMU6310", "UMU6320", "UMU6103", "UMU6102", "UMU6100", "UMU6070", "UMU6000", "UMUF31E", "UMUF30E"],
        "17TV_STANDARD": ["UM6303", "UM6302", "UM6300", "UM5603", "UM5602", "UM5600", "UM5523", "UM5522", "UM5520", "UM5513", "UM5512", "UM5510", "UM5503", "UM5502", "UM5500", "UM5300", "UM4500", "UM4300", "TH39S", "VF39S"],
        "17TV_ENTRY_CN1": ["UNU7000", "UMU6100"]
    },
    2016: {
        "16TV_PREMIUM": ["UKU9518", "UKS9800", "UKS9500", "UKS9000", "UKS8500", "UKS8000", "UKS7500", "UKS7000", "UKS6900", "UKU66A0", "UKU6510", "UKU6500", "UKU64A0", "UKU6400"],
        "16TV_STANDARD1": ["UKUC30S", "UKU6390", "UKU630D", "UKU6300", "UKU6310", "UKU6100", "UKU6090", "UKU6070", "UKU6000", "UKUF31E", "UKUF30E"],
        "16TV_STANDARD2": ["VF39S", "UK6300", "UK6250", "UK6200", "UK5600", "UK5520", "UK5510", "UK5500", "UK5310", "UK5300", "UK4300", "UKF21E", "UKF20E"],
    },

    2015: {
        "15TV_PREMIUM": ["UJS9900", "UJS9800", "UJS9500", "UJS9000", "UJS9100"],
        "15TV_STANDARD1": ["UJU5900", "UJU5910", "UJU5920", "UJU8000", "UJU7800", "UJU7500", "UJU7000", "UJS8500", "UJS8000", "UJU6900", "UJU6410", "UJU6400", "UJU6300", "UJS6900", "UJU6390", "UJU6000", "UJU6100", "US9A", "UJU6800", "UJU6740", "UJU6700", "UJU6600", "UJU6510", "UJU6500", "UJS8600", "UJS6700", "UJU7090"],
        "15TV_STANDARD2": ["UJ6400", "UJS6000", "UJ6330", "UJ6300", "UJ6240", "UJ6200", "UJ5520", "UJ5510", "UJ5500", "UJ5300", "TE390S"],
        "15TV_ENTRY": ["UK5200", "UJ6203", "UJ5373", "UJ5370", "UJ5303", "UJ5300", "UJ5290", "UJ5278", "UJ5270", "UJ5248", "UJ5205", "UJ5202", "UJ5201", "UJ5200", "UJ5190", "UJ4700", "UJ4600", "UJ4570", "UJ4500", "UJ4303", "UJ4300", "UJ4290", "UJ4200", "UH4501"],
    },

    2014: {
        "14TV_PREMIUM": ["US9WB", "US9WAF", "US9CAF", "US9VF", "US9BF", "US9B", "UHU9800", "UHU9300B", "UHU9300", "UHU9000", "UHU8500CV", "85S9GLF", "UHU8800", "UHU8700", "UHU8550", "UHU8500", "UHU8200", "UHU7500", "UH8800", "UH8000"],
        "14TV_STANDARD1": ["UH7100", "UH7000", "UH6900", "UH6850", "UH6800", "UH6700", "UH6600", "UH6500", "UH6400", "UH6410", "UH6350", "UH6300", "UH6200", "UH6088", "UJ5800", "UH5600", "UH5570", "UH5510", "UH5500", "UH4570", "UH4500"],
        "14TV_STANDARD2": ["UH7000", "UH6700", "UH6600", "UH6510", "UH6500"],
        "14TV_STANDARD3": ["UHU8800", "UHU8700", "UHU8550", "UHU8500", "UHU8200", "UHU7800", "UHU7500", "UHU7250", "UHU7200", "UHU7100", "UHU7000", "UHU6950", "UHU6900", "UHU6830", "UHU6000", "UHU5900"],
        "14TV_ENTRY": ["UH6203", "UH6103", "UH5303", "UH5203", "UH5103", "UH4500", "UH4303", "UH4250", "UH4203", "TD390S"]
    },

    2013: {
        "13TV_PREMIUM": ["UF8500", "UF8200", "UF8000", "UF7500", "UF7450", "UF7400", "UF7000", "PF8500", "KS9C", "KF9500", "US9", "UF9000", "US9V", "US9VCN", "US9CN"],
        "13TV_STANDARD": ["UF7200", "UF7150", "UF7120", "UF7100", "UF7050", "UF6900", "UF6800", "UF6700", "UF6600", "UF6540", "UF6510", "UF6500", "UF6470", "UF6450", "UF6420", "UF6400", "UF6370", "UF6360", "UF6350", "UF6330", "UF6320", "UF6300", "UF6203", "UF6200", "UF5700", "UF5500", "UF5400", "UF5303", "UF5300", "PF5200", "UF4500", "UF4300", "PF5500"]
    },

    2012: {
        "12TV_6Group": ["UE7200", "UE7150", "UE7100", "UE6900", "UE6820", "UE6800", "UE6760", "UE6710", "UE6700", "UE6600", "UE6580", "UE6540", "UE6530", "UE6500", "UE6400", "UE6330", "UE6320", "UE6300", "UE6200", "UE6100", "UE6150", "UE5800", "UE5700", "UE5600", "UE5500", "UE5450", "UE5400", "UE5330", "UE5300", "UE4530", "UE4500", "PE7000", "PE6500", "PE570", "PE550", "PE540", "UF6200", "UF6088"],
        "12MFM_6Group": ["TB750", "TB550"]
    }
}

function getSamsungModelGroup(modelNumber, year, panel) {
    modelNumber = modelNumber.toUpperCase();
    const groups = modelGroupsMap[year];
    if(!groups)
        return { groupName:'Unknown', prefix: "", matchingPart: null};
    
    
    for ( const groupName in groups ) {
        const models = groups[groupName];

        //checking for exact matches
        for( const i in models) {
            const modelPart = models[i];
            let partToCheck = modelPart.substr(1,4);
            if( modelNumber.indexOf(partToCheck)>=0)
                return {groupName:groupName, prefix: "", matchingPart: modelPart};
        }

        //since exact matches are not alwasy the case we check "similar"
        for( const i in models ) {
            const modelPart = models[i];
            let partToCheck = modelPart.substr(1,4);
            if( modelNumber.indexOf(partToCheck)>=0 )
                return { groupName, prefix: "Probably ", matchingPart: modelPart };
            partToCheck = modelPart.substr(1,3);
                if( modelNumber.indexOf(partToCheck)>=0 )
                    return { groupName, prefix: "Might be ", matchingPart: modelPart };
            partToCheck = modelPart.substr(2,5);
            if( modelNumber.indexOf(partToCheck)>=0 )
                return { groupName:groupName, prefix: "Might be ", matchingPart: modelPart };
            partToCheck = modelPart.substr(2,4);
            if( modelNumber.indexOf(partToCheck)>=0 )
                return { groupName:groupName, prefix: "Might be ", matchingPart: modelPart };
        }
        
    }

    return { groupName:'Unknown', prefix: "", matchingPart: null};
}

export { getSamsungModelGroup };
