const skyRegex = new RegExp(''
    + "^(ES|IP)" // start with
    + "([a-z]{0,1})" // country (empty = UKy)
    + "([0-9]{3})" // model code
    + "([a-z]{0,1})" // variant (satellite / cable)
);

const modelNameMap = {
    240: 'Falcon v2',
    160: 'Amidala',
    130: 'X-Wing v1'
}

const modelCodeNameMap = {
    'ES240' : 'Gemini',
    'ESi240' : 'Galileo',
}

const countryMap = {
    '': 'United Kingdom',
    'i': 'Italy',
    'd': 'Germany/Austria'
}

const variantMap = {
    '': '',
    's': 'Satellite',
    'c': 'Cable'
}

function getSkyDetails(modelNumberParam) {
    if(modelNumberParam && modelNumberParam.toUpperCase() === 'IP100') {

        return {
            modelName: "Sky Q Mini",
            modelCodeName: "-",
            manufacturer: "sky",
            region: "?",
            variant: "-",
            os: "android",
            osVersion: "-",
            year: "2018/2017++",
            size: "-"
        }
    }
    const matched = modelNumberParam.match(skyRegex);

    const type = matched[1];
    const country = countryMap[matched[2]] || 'Unknown region code"'+matched[0]+'"';
    const modelName = modelNameMap[matched[3]] || 'Unknown model code "'+modelNameMap[1]+'"';
    const variant = variantMap[matched[4]] || '';
    const modelCodeName = modelCodeNameMap[modelNumberParam] || '';

    return {
        modelName,
        modelCodeName,
        manufacturer: 'sky',
        region: country,
        variant,
        os: type === 'IP' ? 'android' : 'linux',
        osVersion: '-',
        year: '2018',
        size: '-'
    }
}

export {
    skyRegex,
    getSkyDetails,
}
