import { getSamsungModelGroup } from './samsungModelGroup';

const operatingSystems = {
    ORSAY: 'orsay',
    TIZEN: 'tizen',
}

const parsers = [
    {
        name: "QLED & NEO QLED 2019-2022",
        examples: ["QE75QN93A", "QE50QN94A", "QE43Q60R", "QN55QN700A"],
        regexp: new RegExp(''
            + "^" // start with
            + "([QNEGKA]{2})" // qled region
            + "([0-9]{2})" // Size of the Panel
            + "QN?" // Quantum Dot, Neo
            + "([0-9]{2,3})" // Resolution (2 digits=UHD, 3 digits=8K)
            + "(R|T|A|B)" // Year 2019-2022
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                ,// region,
                size,
                res,
                yearCode
            ] = modelNumber.match(regexp);
            const yearMap = { R: 2019, T: 2020, A: 2021, B: 2022 };

            return {
                size,
                year: yearMap[yearCode],
                resolution: res.length === 2 ? "UHD" : "8K"
            };
        }
    },
    {
        name: "QLED Samsung 2017-2018",
        examples: ["QN49Q6F", "QE82Q6FN"],
        regexp: new RegExp(''
            + "^" // start with
            + "Q(N|E|A)" // qled, region
            + "([0-9]{2})" // Size of the Panel
            + "Q[0-9](C|F)"
            + "(N?)" // N=2018, empty=2017
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                ,// region,
                size,
                ,// panel,
                yearCode
            ] = modelNumber.match(regexp);

            return {
                size,
                year: yearCode === "N" ? 2018 : 2017,
                resolution: "UHD"
            };
        }
    },
    {
        name: "New models, Samsung TVs 2017-2022 Full HD, UHD",
        examples: ["UN55NU8000FXZA", "UN65KS950D", "UE48J5000", "UE43TU7000KXXU", "UE43BU8500"],
        regexp: new RegExp(''
            + "^" // start with
            + "(U|G|K)" // code
            + "(N|E|A)" // North America - Europe - Asia
            + "([0-9]{2})" // Size of the Panel
            + "(M|N|R|T|A|B)" // Year 2017-2022
            + "([SU]?)" // Resolution (SUHD, UHD, 1080p; SUHD is basically UHD)
            + "[0-9]" // Series start
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                ,// code,
                ,// region,
                size,
                yearCode,
                res
            ] = modelNumber.match(regexp);
            const yearMap = { M: 2017, N: 2018, R: 2019, T: 2020, A: 2021, B: 2022 };
            let resolution
            switch (res) {
                case "S":
                case "U":
                    resolution = "UHD";
                    break;
                default: resolution = "FHD"
            }

            return {
                size, year: yearMap[yearCode], resolution
            }
        }
    },
    {
        name: "Old Model Number Samsung TVs 2008 – 2016 Full HD, UHD",
        examples: ["UE65HU7500", "UE32K5672"],
        regexp: new RegExp(''
            + "^U" // start with
            + "(N|E|A)" // North America - Europe - Asia
            + "([0-9]{2})" // Size of the Panel
            + "(R|N|M|K|J|H|F|E|D|C|B|A)" // Year 2019-2008
            + "([SUP]?)" // Resolution (SUHD, UHD, Plazma, 1080p)
            + "[0-9]" // Series start
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                ,// region,
                size,
                yearCode,
                res
            ] = modelNumber.match(regexp);
            const yearMap = {
                R: 2019, N: 2018, M: 2017, K: 2016, J: 2015, H: 2014,
                F: 2013, E: 2012, D: 2011, C: 2010, B: 2009, A: 2008
            };
            let resolution
            switch (res) {
                case "S":
                case "U":
                    resolution = "UHD";
                    break;
                default: resolution = "FHD"
            }

            return { size, year: yearMap[yearCode], resolution }
        }
    },
]

// TODO: support LS Lifestyle TV

const isSamsung = (modelNumber) => {
    return parsers.some(
        parser => parser.regexp.test(modelNumber)
    )
}

function getSamsungDetails(modelNumberParam) {
    const modelNumber = modelNumberParam.toUpperCase()

    const parser = parsers.find(({ regexp }) => regexp.test(modelNumber));
    const { size = "", year = "", resolution = "" } = parser.getData(parser.regexp, modelNumber)

    const modelGroup = getSamsungModelGroup(modelNumber, year);

    let os, osVersion;
    if (year >= 2016) {
        os = operatingSystems.TIZEN

        switch (year) {
            case 2022:
                osVersion = '6.5'
                break
            case 2021:
                osVersion = '6.0'
                break
            case 2020:
                osVersion = '5.5'
                break
            case 2019:
                osVersion = '5.0'
                break
            case 2018:
                osVersion = '4.0'
                break
            case 2017:
                osVersion = '3.0'
                break
            case 2016:
                osVersion = '2.4'
                break
            default:
        }

    } else if (year === 2015) {
        // 2015 a weird year
        os = `${operatingSystems.TIZEN} or ${operatingSystems.ORSAY}`
        osVersion = 'Tizen 2.3 or Orsay'
    } else if (year >= 2012) {
        os = operatingSystems.ORSAY
    }

    return {
        os,
        osVersion,
        size,
        year,
        manufacturer: 'samsung',
        resolution,
        modelGroup: modelGroup
    }
}

export {
    isSamsung,
    getSamsungDetails,
    parsers,
}
