const manufacturers = {
    SAMSUNG: "samsung",
    LG: "lg",
    SKY: "sky",
    PANASONIC: "panasonic"
};

export {
    manufacturers,
}
