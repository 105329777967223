const yearCodeMap = {
    A: 2014,
    C: 2015,
    D: 2016,
    E: 2017,
    F: 2018,
    G: 2019,
    H: 2020,
    J: 2021,
    L: 2022,
}
const screensMap = {
    S: "FHD",
    X: "UHD",
    R: "Curved UHD",
    Z: "OLED",
}

const parsers = [
    {
        name: "LG LED TVs",
        examples: ["TX-55AX900", "TX-40CX400", "TX-58DX780", "TX-65FXW784", "TX-65LZ1500", "TX-24ESW504", "TC-58DX800"],
        regexp: new RegExp("^"
            + "T(X|C|H)-" // region
            + "([0-9]{2})" // size of the Panel
            + `(${Object.keys(yearCodeMap).join("|")})` // year
            + "(S|X|R|Z)" // resolution
        ),
        getData: (regexp, modelNumber) => {
            const [
                ,
                , // region,
                size,
                yearCode,
                res
            ] = modelNumber.match(regexp);

            return {
                size,
                year: yearCodeMap[yearCode],
                resolution: screensMap[res]
            };

        }
    },
]

const isPanasonic = (modelNumber) => {
    return parsers.some(
        parser => parser.regexp.test(modelNumber)
    )
}

function getPanasonicDetails(modelNumberParam) {
    const modelNumber = modelNumberParam.toUpperCase()

    const parser = parsers.find(({ regexp }) => regexp.test(modelNumber));
    const { size = "", year = "", resolution = "" } = parser.getData(parser.regexp, modelNumber)

    let osVersion;
    if (year <= 2016) {
        osVersion = "v2";
    } else {
        osVersion = "v3";
    }

    const underpowered = (year === 2017 || year === 2018) && resolution === screensMap.S

    return {
        manufacturer: "panasonic",
        year,
        os: "HTML5 SDK",
        osVersion,
        size,
        resolution,
        underpowered,
    }
}

export {
    isPanasonic, getPanasonicDetails, parsers
}
