function LgAdditionalInfo({ modelInfo }) {
    if (!modelInfo.nonSmartTv) {
        return null;
    }

    return (
        <div className="warning">
            {modelInfo.nonSmartTv}
        </div>
    )
}

export default LgAdditionalInfo;
